<template>
  <div class="users-wrapper__users">
    <div class="grid-wrapper">
      <div class="col col-12">
        <div class="grid-info">
          <span class="grid-heading">
            <h1>{{ $t('Users') }}</h1>
            <span class="entries">{{ NumberFormat(list.items.length) }} {{ $t(`entr${list.items.length == 1 ? 'y' : 'ies'}`) }}</span>
          </span>
          <div class="grid-actions">
            <a class="button green" @click.prevent="DownloadUsersCSV" href="">{{ $t('Download CSV') }}</a>
            <a class="button blue" @click.prevent="CreateUser" href="">{{ $t('Create') }}</a>
          </div>
          <div class="grid-search">
            <input type="text" @keypress.enter="SearchUsers" :value="search.query" :placeholder="$t('Search by name')">
          </div>
        </div>
        <table class="user-list table odd-even">
          <thead>
            <tr class="user-list__actions">
              <th class="name sort" :class="list.sort.column == 'name' && list.sort.by == 'string' && ['dir', list.sort.dir]" @click="Sort(list, 'items', 'string', 'full_name', 'name')">
                <span>{{ $t('Name') }}</span>
              </th>
              <th class="status sort" :class="list.sort.column == 'status' && list.sort.by == 'string' && ['dir', list.sort.dir]" @click="Sort(list, 'items', 'string', 'status', 'status')">
                <span>{{ $t('Status') }}</span>
              </th>
              <th class="login sort" :class="list.sort.column == 'login' && list.sort.by == 'date' && ['dir', list.sort.dir]" @click="Sort(list, 'items', 'date', 'last_login', 'login')">
                <span>{{ $t('Login') }}</span>
              </th>
              <th class="start sort" :class="list.sort.column == 'start' && list.sort.by == 'date' && ['dir', list.sort.dir]" @click="Sort(list, 'items', 'date', 'employment_day', 'start')">
                <span>{{ $t('Start date') }}</span>
              </th>
              <th class="role">{{ $t('Permissions') }}</th>
              <th class="email sort" :class="list.sort.column == 'email' && list.sort.by == 'string' && ['dir', list.sort.dir]" @click="Sort(list, 'items', 'string', 'email', 'email')">
                <span>{{ $t('Email') }}</span>
              </th>
              <th class="edit"></th>
            </tr>
          </thead>
          <tbody>
            <tr :data-id="user.id" class="user-list__row clickable" @mousedown="ClickOpen" @mousemove="ClickOpen" @mouseup="ClickOpen" :key="index" v-for="(user, index) in list.items">
              <td class="name">{{ TitleCase(user.full_name) }}</td>
              <td class="status">
                <div class="flex-row state" :class="[user.disabled ? 'negative' : 'positive']">
                  {{ $t((user.disabled ? 'Ina' : 'A') + 'ctive') }}
                </div>
              </td>
              <td class="login">{{ user.last_login && DateFormat(user.last_login) }}</td>
              <td class="start">{{ user.employment_day && DateOnly(user.employment_day) }}</td>
              <td class="role">
                <div class="truncate capitalize">{{ GetRoles(user.permission_ids) }}</div>
              </td>
              <td class="email" v-html="Hyperlink({scheme: 'mailto', href: String(user.email).toLowerCase()})" />
              <td @mouseenter="SetBackground" @mouseleave="SetBackground" class="actions">
                <a @click.prevent="" href="" class="icon dots">
                  <ul class="item-actions">
                    <li><a @click.prevent="ViewUser(user.id)" href="">{{ $t('View') }}</a></li>
                    <li><a @click.prevent="EditUser(user.id)" href="">{{ $t('Edit') }}</a></li>
                    <li><a @click.prevent="DeleteUser(user.id)" href="" class="error">{{ $t('Delete') }}</a></li>
                  </ul>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <Modal modal="create_user" :value="modal.create_user.open" :title="$t('New user')">
      <div id="create-user">
        <div class="modal-content scrollable">
          <form class="form" @submit.prevent="CreateUserSubmit" autocomplete="off">
            <div class="form-group" id="user">
              <label class="fieldset" for="">
                <label style="display: flex; justify-content: space-between; margin-bottom: 0;">
                  <label for="name" class="required" style="width: 100%; margin-right: 30px;">
                    <span class="span">{{ $t('First name') }}</span>
                    <input @input="ValidateInput" class="text" type="text" :placeholder="$t('First name')" name="name" id="name" tabindex="1">
                  </label>

                  <label for="surname" class="required" style="width: 100%;">
                    <span class="span">{{ $t('Last name') }}</span>
                  <input @input="ValidateInput" class="text" type="text" :placeholder="$t('Last name')" name="surname" id="surname" tabindex="2">
                  </label>
                </label>

                <label for="address" class="required">
                  <span class="span">{{ $t('Address') }}</span>
                  <input @input="ValidateInput" class="text" type="text" :placeholder="$t('Address')" name="address" id="address" tabindex="3">
                </label>

                <label style="display: flex; justify-content: space-between; margin-bottom: 0;">
                  <label for="zipcode" class="required" style="width: 100%; margin-right: 30px;">
                    <span class="span">{{ $t('Postal code') }}</span>
                    <input @input="ValidateInput" class="number" type="number" :placeholder="$t('Postal code')" name="zipcode" id="zipcode" tabindex="4">
                  </label>

                  <label for="city" class="required" style="width: 100%;">
                    <span class="span">{{ $t('City') }}</span>
                    <input @input="ValidateInput" class="text" type="text" :placeholder="$t('City')" name="city" id="city" tabindex="5">
                  </label>
                </label>

                <label for="email" class="required" style="margin-bottom: 50px;">
                  <span class="span">{{ $t('Email') }}</span>
                  <input @input="ValidateInput" class="email" type="email" :placeholder="$t('Email')" name="email" id="email" tabindex="6">
                </label>

                <label style="display: flex; margin-bottom: 50px;">
                  <div style="width:220px; margin-right: 30px;">
                    <!-- <span class="span show">{{ $t('Country') }}</span> -->
                    <input name="prefix-phone" v-model="phone.selected.user.code" hidden required>
                    <v-select v-model="phone.selected.user" :filter="fuseSearch" :options="phone.options.map(o => {o.name = CountryName(o.iso); return o;}).sort((a, b) => {
                      a = a.name.toUpperCase(); b = b.name.toUpperCase(); return a < b ? -1 : a > b ? 1 : 0;
                    })" :clearable="false" :tabindex="7">
                      <template v-slot:selected-option="option">
                        <div class="truncate">
                          <Flag :code="option.iso" size="small" />
                          <span>{{ option.code }}</span>
                        </div>
                      </template>
                      <template slot="option" slot-scope="option">
                        <div class="truncate">
                          <Flag :code="option.iso" size="small" />
                          <span>{{ option.code }}</span>
                        </div>
                      </template>
                    </v-select>
                  </div>

                  <label for="phone" class="required" style="width: 100%; margin-bottom: 0px;">
                    <span class="span">{{ $t('Phone') }}</span>
                    <input @input="ValidateInput" class="number" type="text" pattern="^\+?\d+$" :placeholder="$t('Phone')" name="phone" id="phone" tabindex="8">
                  </label>
                </label>

                <DatePicker name="employment-day" label="Start date" tabindex="9" />

                <Select name="permissions" mode="multi" required="true" :list="roles" tabindex="10" />

                <Select name="status" mode="single" required="true" :list="{0: $t('Inactive'), 1: $t('Active')}" tabindex="11" />

                <label style="display: flex; justify-content: space-between; margin-bottom: 0px;">
                  <label for="commission-pay" style="margin-top: 50px; margin-right: 30px;">
                    <span class="span">{{ $t('Commission') + ' ' + ('(' + $t('Pack') + ')').toLowerCase() }}</span>
                    <input @input="ValidateInput" class="number" type="number" :placeholder="$t('Commission') + ' ' + ('(' + $t('Pack') + ')').toLowerCase()" name="commission-pay" id="commission-pay" step=".01" tabindex="14">
                  </label>

                  <label for="commission-pay-pick" style="margin-top: 50px;">
                    <span class="span">{{ $t('Commission') + ' ' + ('(' + $t('Pick') + ')').toLowerCase() }}</span>
                    <input @input="ValidateInput" class="number" type="number" :placeholder="$t('Commission') + ' ' + ('(' + $t('Pick') + ')').toLowerCase()" name="commission-pay-pick" id="commission-pay-pick" step=".01" tabindex="15">
                  </label>
                </label>

                <label style="display: flex; justify-content: space-between; margin-bottom: 0px;">
                  <label for="hourly-pay" style="margin-bottom: 50px; margin-right: 30px;">
                    <span class="span">{{ $t('Hourly rate') }}</span>
                    <input @input="ValidateInput" class="number" type="number" :placeholder="$t('Hourly rate')" name="hourly-pay" id="hourly-pay" step=".01" tabindex="16">
                  </label>

                  <label for="hourly-guaranteed-salary" style="margin-bottom: 50px;">
                    <span class="span">{{ $t('Guaranteed salary') }}</span>
                    <input @input="ValidateInput" class="number" type="number" :placeholder="$t('Guaranteed salary')" name="hourly-guaranteed-salary" id="hourly-guaranteed-salary" step=".01" tabindex="17">
                  </label>
                </label>

                <h3 style="margin-top: 20px;">{{ $t('Contact information of guardian') }}</h3>

                <label style="display: flex; justify-content: space-between; margin-top: 50px; margin-bottom: 0;">
                  <label for="guardian-name" class="required" style="width: 100%; margin-right: 30px;">
                    <span class="span">{{ $t('First name') }}</span>
                    <input @input="ValidateInput" class="text" type="text" :placeholder="$t('First name')" name="guardian-name" id="guardian-name" tabindex="21">
                  </label>

                  <label for="guardian-surname" class="required" style="width: 100%;">
                    <span class="span">{{ $t('Last name') }}</span>
                  <input @input="ValidateInput" class="text" type="text" :placeholder="$t('Last name')" name="guardian-surname" id="guardian-surname" tabindex="23">
                  </label>
                </label>

                <label for="guardian-email" class="" style="margin-bottom: 50px;">
                  <span class="span">{{ $t('Email') }}</span>
                  <input @input="ValidateInput" class="guardian-email" type="email" :placeholder="$t('Email')" name="guardian-email" id="guardian-email" tabindex="24">
                </label>

                <label style="display: flex; margin-bottom: 0px;">
                  <div style="width:220px; margin-right: 30px;">
                    <!--<span class="span show">{{ $t('Country') }}</span> -->
                    <input name="guardian-prefix-phone" v-model="phone.selected.guardian.code" hidden required>
                    <v-select class="drop-up" v-model="phone.selected.guardian" :filter="fuseSearch" :options="phone.options.map(o => {o.name = CountryName(o.iso); return o;}).sort((a, b) => {
                      a = a.name.toUpperCase(); b = b.name.toUpperCase(); return a < b ? -1 : a > b ? 1 : 0;
                    })" append-to-body :calculate-position="withPopper" :clearable="false" :tabindex="24">
                      <template v-slot:selected-option="option">
                        <div class="truncate">
                          <Flag :code="option.iso" size="small" />
                          <span>{{ option.code }}</span>
                        </div>
                      </template>
                      <template slot="option" slot-scope="option">
                        <div class="truncate">
                          <Flag :code="option.iso" size="small" />
                          <span>{{ option.code }}</span>
                        </div>
                      </template>
                    </v-select>
                  </div>

                  <label for="guardian-phone" class="" style="width: 100%; margin-bottom: 0px;">
                    <span class="span">{{ $t('Phone') }}</span>
                    <input @input="ValidateInput" class="number" type="text" pattern="^\+?\d+$" :placeholder="$t('Phone')" name="guardian-phone" id="guardian-phone" tabindex="26">
                  </label>
                </label>
              </label>

              <label class="fieldset" for="" style="margin-left: 30px;">
                <div class="image-cropper" style="margin-bottom: 35px;">
                  <VueCroppieUpload ref="image" :src="modal.create_user.image.src" @crop="CropImage('create_user', $event)" />
                </div>

                <label for="password" class="required">
                  <span class="span">{{ $t('Password') }}</span>
                  <input @input="ValidateInput" class="password" type="password" :placeholder="$t('Password')" name="password" id="password" autocomplete="new-password" tabindex="12">
                </label>

                <label for="repeat-password" class="required">
                  <span class="span">{{ $t('Repeat password') }}</span>
                  <input @input="ValidateInput" class="repeat-password" type="password" :placeholder="$t('Repeat password')" name="repeat-password" id="repeat-password" autocomplete="new-password" tabindex="13">
                </label>

                <label style="display: flex; justify-content: space-between; margin-top: 50px; margin-bottom: 0px;">
                  <label for="employee-no" :class="{filled: user.employee_no}" style="width: 100%; margin-right: 30px; margin-bottom: 0px;">
                    <span :class="['span', {show: user.employee_no}]">{{ $t('Employee number') }}</span>
                    <input @input="ValidateInput" class="number" type="number" :placeholder="$t('Employee number')" name="employee-no" id="employee-no" :value="user.employee_no" tabindex="18">
                  </label>

                  <label for="social-security-no" :class="{filled: user.social_security_no}">
                    <span class="span">{{ $t('Social security number') }}</span>
                    <input @input="ValidateInput" class="number" type="number" :placeholder="$t('Social security number')" name="social-security-no" id="social-security-no" tabindex="19">
                  </label>
                </label>

                <label style="display: flex; justify-content: space-between; margin-bottom: 50px;">
                  <label for="reg-no" class="required" style="width: 100%; margin-right: 30px; margin-bottom: 0px;">
                    <span class="span">{{ $t('Reg. no.') }}</span>
                    <input @input="ValidateInput" class="number" type="number" :placeholder="$t('Reg. no.')" name="reg-no" id="reg-no" tabindex="20">
                  </label>

                  <label for="account-no" class="required" style="width: 100%; margin-bottom: 50px;">
                    <span class="span">{{ $t('Account no.') }}</span>
                    <input @input="ValidateInput" class="number" type="number" :placeholder="$t('Account no.')" name="account-no" id="account-no" tabindex="21">
                  </label>
                </label>
              </label>
            </div>

            <div class="form-action">
              <input class="submit done" style="width: 115px;" type="submit" :value="$t('Save')">
            </div>
          </form>
        </div>
      </div>
    </Modal>

    <Modal modal="view_user" :value="modal.view_user.open" :title="`<span>${TitleCase(user.name + ' ' + user.surname)}</span><span>${user.last_login ? DateFormat(user.last_login) : ''}</span>`">
      <div id="view-user" class="scrollable">
        <div class="modal-header">
          <!--
          <div class="shipping trackingnumber" style="display: flex; align-items: center;">
            <span :class="['courier-icon', shipment.agent.code]" :title="shipment.agent.label" />
            <span class="shipping-method">{{ shipment.method.label }}</span>
          </div>
          -->
        </div>
        <div class="modal-content scrollable" style="padding-bottom: 25px;">
          <div class="information">
            <div class="view-user__information">
              <p><b>{{ $t('User') + ' ID' }}:</b> <span>{{ user.id }}</span></p>
              <p><b :style="!user.name && 'opacity: 0.3'">{{ $t('First name') }}:</b> <span v-if="user.name">{{ TitleCase(user.name || '') }}</span></p>
              <p><b :style="!user.surname && 'opacity: 0.3'">{{ $t('Last name') }}:</b> <span v-if="user.surname">{{ TitleCase(user.surname || '') }}</span></p>
              <p><b :style="!user.address && 'opacity: 0.3'">{{ $t('Address') }}:</b> <span v-if="user.address" v-html="Hyperlink({scheme: 'map', target: '_blank', href: String(`${user.address + (user.zipcode || user.city ? ', ' : '')} ${user.zipcode} ${user.city}`).trim(), text: user.address})" /></p>
              <p><b>
                <b :style="!user.zipcode && 'opacity: 0.3'">{{ $t('ZIP code') }}</b>&nbsp;
                <b :style="!(user.zipcode && user.city) && 'opacity: 0.3'"></b>
                <b :style="!user.city && 'opacity: 0.3'">{{ $t('City').toLowerCase() }}</b>
                <b :style="!(user.zipcode || user.city) && 'opacity: 0.3'">:</b></b>&nbsp;
                <span v-if="user.zipcode || user.city" v-html="Hyperlink({scheme: 'map', target: '_blank', href: String(`${user.zipcode} ${user.city}`).trim(), text: `${user.zipcode || ''}${user.city ? `${(user.zipcode ? ' ' : '') + user.city}` : ''}`})" /></p>
              <p><b :style="!user.country && 'opacity: 0.3'">{{ $t('Country') }}:</b> <span v-if="user.country" style="display: flex; align-items: center;">
                <Flag :code="user.country.code" size="small" />
                <span style="margin-left: 10px;" v-html="Hyperlink({scheme: 'map', target: '_blank', href: CountryName(user.country.code)})" /></span>
              </p>
              <p><b :style="!user.email && 'opacity: 0.3'">{{ $t('Email') }}:</b> <span v-html="Hyperlink({scheme: 'mailto', href: String(user.email).toLowerCase()})" /></p>
              <p><b :style="!user.phone && 'opacity: 0.3'">{{ $t('Phone') }}:</b> <span  v-html="Hyperlink({scheme: 'tel', href: (user.prefix_phone || '') + user.phone, text: `${user.prefix_phone ? `(${user.prefix_phone})` : ''} ${user.phone}`})" /></p>
            </div>
            <div class="view-user__information" style="padding: 0px; margin-right: 0px;">
              <div :class="['user-image', {background: !modal.view_user.image.src}]" :style="[{
                '--src': modal.view_user.image.src && `url(${modal.view_user.image.src})`,
                '--background': modal.view_user.image.src && '#ffffff',
                '--backdrop': 'grayscale(50%)' + (modal.view_user.image.src && ' brightness(97%)'),
                '--filter': modal.view_user.image.src && 'blur(10px)'
              }]" />
            </div>
          </div>
          <div class="information">
            <div class="view-user__information" style="justify-content: flex-start; border: 2px dashed rgb(234, 234, 234); padding: 30px; height: fit-content;">
              <p><b :style="!user.employment_day && 'opacity: 0.3'">{{ $t('Start date') }}:</b> <span v-if="user.employment_day">{{ DateOnly(user.employment_day) }}</span></p>
              <p><b :style="!(user.permission_ids || []).length && 'opacity: 0.3'">{{ $t('Permissions') }}:</b> <span v-if="(user.permission_ids || []).length" class="capitalize">{{ GetRoles(user.permission_ids) }}</span></p>
              <p><b :style="!(/true|false/.test(user.disabled)) && 'opacity: 0.3'">{{ $t('Status') }}:</b> <span v-if="/true|false/.test(user.disabled)" class="flex-row state" :class="[user.disabled ? 'negative' : 'positive']">{{ $t((user.disabled ? 'Ina' : 'A') + 'ctive') }}</span></p>
              <p><b :style="isNaN(user.commission_pay) && 'opacity: 0.3'">{{ $t('Commission') + ' ' + ('(' + $t('Pack') + ')').toLowerCase() }}:</b> <span v-if="!isNaN(user.commission_pay)">Kr. {{ CurrencyFormat(user.commission_pay) }}</span></p>
              <p><b :style="isNaN(user.commission_pay_pick) && 'opacity: 0.3'">{{ $t('Commission') + ' ' + ('(' + $t('Pick') + ')').toLowerCase()  }}:</b> <span v-if="!isNaN(user.commission_pay_pick)">Kr. {{ CurrencyFormat(user.commission_pay_pick) }}</span></p>
              <p><b :style="isNaN(user.hourly_pay) && 'opacity: 0.3'">{{ $t('Hourly rate') }}:</b> <span v-if="!isNaN(user.hourly_pay)">Kr. {{ CurrencyFormat(user.hourly_pay) }}</span></p>
              <p><b :style="isNaN(user.hourly_guarantee_salary) && 'opacity: 0.3'">{{ $t('Guaranteed salary') }}:</b> <span v-if="!isNaN(user.hourly_guarantee_salary)">Kr. {{ CurrencyFormat(user.hourly_guarantee_salary) }}</span></p>
            </div>
            <div class="view-user__information" style="background-color: #f1f2f6; margin-right: 0px;">
              <p><b :style="!user.employee_no && 'opacity: 0.3'">{{ $t('Employee number') }}:</b> <span v-if="user.employee_no">{{ user.employee_no || '' }}</span></p>
              <p><b :style="!user.social_security_no && 'opacity: 0.3'">{{ $t('Social security number') }}:</b> <span v-if="user.social_security_no">{{ user.social_security_no || '' }}</span></p>
              <p><b :style="!user.reg_no && 'opacity: 0.3'">{{ $t('Reg. no.') }}:</b> <span v-if="user.reg_no">{{ user.reg_no || '' }}</span></p>
              <p><b :style="!user.account_no && 'opacity: 0.3'">{{ $t('Account no.') }}:</b> <span v-if="user.account_no">{{ user.account_no || '' }}</span></p>
              <div style="width: 100%; margin-top: 20px; padding: 20px 20px 10px; position: relative; display: flex; flex-direction: column; border: 2px solid rgb(107 107 107);" v-if="Object.keys(this.user).filter(key => /guardian/.test(key) && this.user[key]).length > 1">
                <h4 style="top: -10px; left: 10px; height: 20px; padding: 0 10px; line-height: 1; position: absolute; background-color: rgb(241, 242, 246);"><b>{{ $t('Guardian') }}</b></h4>
                <p><b :style="!user.guardian_name && 'opacity: 0.3'">{{ $t('First name') }}:</b> <span v-if="user.guardian_name">{{ user.guardian_name || '' }}</span></p>
                <p><b :style="!user.guardian_surname && 'opacity: 0.3'">{{ $t('Last name') }}:</b> <span v-if="user.guardian_surname">{{ user.guardian_surname || '' }}</span></p>
                <p><b :style="!user.guardian_email && 'opacity: 0.3'">{{ $t('Email') }}:</b> <span v-if="user.guardian_email" v-html="Hyperlink({scheme: 'mailto', href: String(user.guardian_email || '').toLowerCase()})" /></p>
                <p><b :style="!(user.guardian_phone_predix && user.guardian_phone) && 'opacity: 0.3'">{{ $t('Phone') }}:</b> <span v-if="user.guardian_phone_predix && user.guardian_phone" v-html="Hyperlink({scheme: 'tel', href: (user.guardian_phone_predix || '') + user.guardian_phone, text: `${user.guardian_phone_predix ? `(${user.guardian_phone_predix})` : ''} ${user.guardian_phone}`})" /></p>
              </div>
            </div>
          </div>
        </div>
        <!--
        <div class="modal-footer">
          <div class="button-container actions">
            <button class="button green" @click.prevent="DownloadUserCSV">{{ $t('Download CSV') }}</button>
          </div>
        </div>
        -->
      </div>
    </Modal>

    <Modal modal="edit_user" :value="modal.edit_user.open" :title="`${$t('User')} #${user.id}`">
      <div id="edit-user">
        <form class="form" @keydown.enter="$event.preventDefault" @submit.prevent="EditUserSubmit" action="#" autocomplete="off">
          <div class="form-group" for="" id="user">
            <label class="fieldset" for="">
              <label style="display: flex; justify-content: space-between; margin-bottom: 0px;">
                <label for="name" :class="['required', {filled: user.name}]" style="width: 100%; margin-right: 30px;">
                  <span :class="['span', {show: user.name}]">{{ $t('First name') }}</span>
                  <input @input="ValidateInput" class="text" type="text" :placeholder="$t('First name')" name="name" id="name" :value="user.name" tabindex="1">
                </label>

                <label for="surname" :class="['required', {filled: user.surname}]" style="width: 100%;">
                  <span :class="['span', {show: user.surname}]">{{ $t('Last name') }}</span>
                  <input @input="ValidateInput" class="text" type="text" :placeholder="$t('Last name')" name="surname" id="surname" :value="user.surname" tabindex="2">
                </label>
              </label>

              <label for="address" :class="['required', {filled: user.address}]">
                <span :class="['span', {show: user.address}]">{{ $t('Address') }}</span>
                <input @input="ValidateInput" class="text" type="text" :placeholder="$t('Address')" name="address" id="address" :value="user.address" tabindex="3">
              </label>

              <label style="display: flex; justify-content: space-between; margin-bottom: 0;">
                <label for="zipcode" :class="['required', {filled: user.zipcode}]" style="width: 100%; margin-right: 30px;">
                  <span :class="['span', {show: user.zipcode}]">{{ $t('Postal code') }}</span>
                  <input @input="ValidateInput" class="number" type="number" :placeholder="$t('Postal code')" name="zipcode" id="zipcode" :value="user.zipcode" tabindex="4">
                </label>

                <label for="city" :class="['required', {filled: user.city}]" style="width: 100%;">
                  <span :class="['span', {show: user.city}]">{{ $t('City') }}</span>
                  <input @input="ValidateInput" class="text" type="text" :placeholder="$t('City')" name="city" id="city" :value="user.city" tabindex="5">
                </label>
              </label>

              <label for="email" :class="['required', {filled: user.email}]" style="margin-bottom: 50px;">
                <span :class="['span', {show: user.email}]">{{ $t('Email') }}</span>
                <input @input="ValidateInput" class="email" type="email" :placeholder="$t('Email')" name="email" id="email" :value="user.email" tabindex="6">
              </label>

              <label style="display: flex; margin-bottom: 50px;">
                <div style="width: 220px; margin-right: 30px;">
                  <!-- <span class="span show">{{ $t('Country') }}</span> -->
                  <input name="prefix-phone" v-model="phone.selected.user.code" hidden required>
                  <v-select v-model="phone.selected.user" :filter="fuseSearch" :options="phone.options.map(o => {o.name = CountryName(o.iso); return o;}).sort((a, b) => {
                    a = a.name.toUpperCase(); b = b.name.toUpperCase(); return a < b ? -1 : a > b ? 1 : 0;
                  })" :clearable="false" :tabindex="7">
                    <template v-slot:selected-option="option">
                      <div class="truncate">
                        <Flag :code="option.iso" size="small" />
                        <span>{{ option.code }}</span>
                      </div>
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="truncate">
                        <Flag :code="option.iso" size="small" />
                        <span>{{ option.code }}</span>
                      </div>
                    </template>
                  </v-select>
                </div>

                <label for="phone" :class="['required', {filled: user.phone}]" style="width: 100%; margin-bottom: 0px;">
                  <span :class="['span', {show: user.phone}]">{{ $t('Phone') }}</span>
                  <input @input="ValidateInput" class="number" type="text" pattern="^\+?\d+$" :placeholder="$t('Phone')" name="phone" id="phone" :value="user.phone" tabindex="8">
                </label>
              </label>

              <DatePicker name="employment-day" label="Start date" :selected="user.employment_day" tabindex="9" />

              <Select name="permissions" mode="multi" required="true" :list="roles" :selected="user.permission_ids" tabindex="10" />

              <Select name="status" mode="single" required="true" :list="{0: $t('Inactive'), 1: $t('Active')}" :selected="user.disabled ? [0] : [1]" tabindex="11" />

              <label style="display: flex; justify-content: space-between; margin-bottom: 0px;">
                <label for="commission-pay" :class="{filled: !isNaN(user.commission_pay)}" style="margin-top: 50px; margin-right: 30px;">
                  <span :class="['span', {show: !isNaN(user.commission_pay)}]">{{ $t('Commission') + ' ' + ('(' + $t('Pack') + ')').toLowerCase() }}</span>
                  <input @input="ValidateInput" class="number" type="number" :placeholder="$t('Commission') + ' ' + ('(' + $t('Pack') + ')').toLowerCase()" name="commission-pay" id="commission-pay" step=".01" :value="user.commission_pay || 0" tabindex="14">
                </label>

                <label for="commission-pay-pick" :class="{filled: !isNaN(user.commission_pay_pick)}" style="margin-top: 50px;">
                  <span :class="['span', {show: !isNaN(user.commission_pay_pick)}]">{{ $t('Commission') + ' ' + ('(' + $t('Pick') + ')').toLowerCase() }}</span>
                  <input @input="ValidateInput" class="number" type="number" :placeholder="$t('Commission') + ' ' + ('(' + $t('Pick') + ')').toLowerCase()" name="commission-pay-pick" id="commission-pay-pick" step=".01" :value="user.commission_pay_pick || 0" tabindex="15">
                </label>
              </label>

              <label style="display: flex; justify-content: space-between; margin-bottom: 0px;">
                <label for="hourly-pay" :class="{filled: !isNaN(user.hourly_pay)}" style="margin-bottom: 50px; margin-right: 30px;">
                  <span :class="['span', {show: !isNaN(user.hourly_pay)}]">{{ $t('Hourly rate') }}</span>
                  <input @input="ValidateInput" class="number" type="number" :placeholder="$t('Hourly rate')" name="hourly-pay" id="hourly-pay" step=".01" :value="user.hourly_pay || 0" tabindex="16">
                </label>

                <label for="hourly-guaranteed-salary" :class="{filled: !isNaN(user.hourly_guarantee_salary)}" style="margin-bottom: 50px;">
                  <span :class="['span', {show: !isNaN(user.hourly_guarantee_salary)}]">{{ $t('Guaranteed salary') }}</span>
                  <input @input="ValidateInput" class="number" type="number" :placeholder="$t('Guaranteed salary')" name="hourly-guaranteed-salary" id="hourly-guaranteed-salary" step=".01" :value="user.hourly_guarantee_salary || 0" tabindex="17">
                </label>
              </label>

              <h3 style="margin-top: 20px;">{{ $t('Contact information of guardian') }}</h3>

              <label style="display: flex; justify-content: space-between; margin-top: 50px; margin-bottom: 0;">
                <label for="guardian-name" class="required" style="width: 100%; margin-right: 30px;">
                  <span class="span">{{ $t('First name') }}</span>
                  <input @input="ValidateInput" class="text" type="text" :placeholder="$t('First name')" name="guardian-name" id="guardian-name" :value="user.guardian_name" tabindex="22">
                </label>

                <label for="guardian-surname" class="required" style="width: 100%;">
                  <span class="span">{{ $t('Last name') }}</span>
                <input @input="ValidateInput" class="text" type="text" :placeholder="$t('Last name')" name="guardian-surname" id="guardian-surname" :value="user.guardian_surname" tabindex="23">
                </label>
              </label>

              <label for="guardian-email" class="" style="margin-bottom: 50px;">
                <span class="span">{{ $t('Email') }}</span>
                <input @input="ValidateInput" class="guardian-email" type="email" :placeholder="$t('Email')" name="guardian-email" id="guardian-email" :value="user.guardian_email" tabindex="24">
              </label>

              <label style="display: flex; margin-bottom: 0px;">
                <div style="width:220px; margin-right: 30px;">
                  <!--<span class="span show">{{ $t('Country') }}</span> -->
                  <input name="guardian-prefix-phone" v-model="phone.selected.guardian.code" hidden required>
                  <v-select class="drop-up" v-model="phone.selected.guardian" :filter="fuseSearch" :options="phone.options.map(o => {o.name = CountryName(o.iso); return o;}).sort((a, b) => {
                    a = a.name.toUpperCase(); b = b.name.toUpperCase(); return a < b ? -1 : a > b ? 1 : 0;
                  })" append-to-body :calculate-position="withPopper" :clearable="false" :tabindex="24">
                    <template v-slot:selected-option="option">
                      <div class="truncate">
                        <Flag :code="option.iso" size="small" />
                        <span>{{ option.code }}</span>
                      </div>
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="truncate">
                        <Flag :code="option.iso" size="small" />
                        <span>{{ option.code }}</span>
                      </div>
                    </template>
                  </v-select>
                </div>

                <label for="guardian-phone" class="" style="width: 100%; margin-bottom: 0px;">
                  <span class="span">{{ $t('Phone') }}</span>
                  <input @input="ValidateInput" class="number" type="text" pattern="^\+?\d+$" :placeholder="$t('Phone')" name="guardian-phone" id="guardian-phone" :value="user.guardian_phone" tabindex="25">
                </label>
              </label>
            </label>

            <label class="fieldset" for="" style="margin-left: 30px;">
              <div class="image-cropper" style="margin-bottom: 35px;">
                <VueCroppieUpload ref="image" :src="modal.edit_user.image.src" @crop="CropImage('edit_user', $event)" @init="InitImage('edit_user', $event)" />
              </div>

              <label for="password">
                <span class="span">{{ $t('Password') }}</span>
                <input @input="ValidateInput" class="password" type="password" :placeholder="$t('Password')" name="password" id="password" autocomplete="new-password" tabindex="12">
              </label>

              <label for="repeat-password">
                <span class="span">{{ $t('Repeat password') }}</span>
                <input @input="ValidateInput" class="repeat-password" type="password" :placeholder="$t('Repeat password')" name="repeat-password" id="repeat-password" autocomplete="new-password" tabindex="13">
              </label>

              <label style="display: flex; justify-content: space-between; margin-top: 50px; margin-bottom: 0px;">
                <label for="employee-no" :class="{filled: user.employee_no}" style="width: 100%; margin-right: 30px; margin-bottom: 0px;">
                  <span :class="['span', {show: user.employee_no}]">{{ $t('Employee number') }}</span>
                  <input @input="ValidateInput" class="number" type="number" :placeholder="$t('Employee number')" name="employee-no" id="employee-no" :value="user.employee_no" tabindex="17">
                </label>

                <label for="social-security-no" :class="{filled: user.social_security_no}">
                  <span :class="['span', {show: user.social_security_no}]">{{ $t('Social security number') }}</span>
                  <input @input="ValidateInput" class="number" type="number" :placeholder="$t('Social security number')" name="social-security-no" id="social-security-no" :value="user.social_security_no || ''" tabindex="19">
                </label>
              </label>

              <label style="display: flex; justify-content: space-between; margin-bottom: 50px;">
                <label for="reg-no" :class="{filled: user.reg_no}" style="width: 100%; margin-right: 30px; margin-bottom: 0px;">
                  <span :class="['span', {show: user.reg_no}]">{{ $t('Reg. no.') }}</span>
                  <input @input="ValidateInput" class="number" type="number" :placeholder="$t('Reg. no.')" name="reg-no" id="reg-no" :value="user.reg_no" tabindex="20">
                </label>

                <label for="account-no" :class="{filled: user.account_no}" style="width: 100%; margin-bottom: 50px;">
                  <span :class="['span', {show: user.account_no}]">{{ $t('Account no.') }}</span>
                  <input @input="ValidateInput" class="number" type="number" :placeholder="$t('Account no.')" name="account-no" id="account-no" :value="user.account_no" tabindex="21">
                </label>
              </label>
            </label>
          </div>

          <div class="form-action">
            <input class="submit done" style="min-width: 115px;" type="submit" :value="$t('Save')">
            <a class="button red" style="min-width: 115px;" @click.prevent="DeleteUser(user.id)" href="">{{ $t('Delete') }}</a>
          </div>
        </form>
      </div>
    </Modal>

    <div class="loading" v-if="loading">
      <div class="loading-element">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>

  </div>
</template>

<script>
  import { FormElementsBehaviour }  from '@/mixins/FormElementsBehaviour';
  import { TableElementsBehaviour } from '@/mixins/TableElementsBehaviour';
  import { createPopper }           from '@popperjs/core';
  import { Tool }                   from '@/helpers/Tool';
  import { BPA }                    from '@/helpers/BPA';
  import Modal                      from '@/components/snippets/Modal';
  import Select                     from '@/components/snippets/Select';
  import DatePicker                 from '@/components/snippets/DatePicker';
  import VueCroppieUpload           from '@/components/snippets/VueCroppieUpload';
  import Flag                       from '@/components/snippets/Flag';
  import Fuse                       from 'fuse.js';

  export default {
    name: 'UsersList',
    mixins: [
      FormElementsBehaviour, 
      TableElementsBehaviour, 
      Tool,
      BPA
    ],
    components: {
      Modal,
      Select,
      DatePicker,
      VueCroppieUpload,
      Flag
    },
    data() {
      return {
        loading: true,
        clickables: [],
        clipboard: null,
        cached: {},
        search: {
          query: '',
        },
        user: {},
        roles: {},
        permissions: {},
        list: {
          items: [],
          sort: {
            column: 'name',
            prop: 'full_name',
            dir: 'asc',
            by: 'string'
          },
        },
        phone: {
          placement: 'top',
          options: [],
          selected: {
            user: '',
            guardian: ''
          },
          default: {
            code: '+45',
            label: '+45',
            name: 'Denmark',
            iso: 'DK'
          }
        },
        modal: {
          create_user: {
            open: false,
            image: {}
          },
          view_user: {
            open: false,
            image: {}
          },
          edit_user: {
            open: false,
            image: {}
          }
        }
      }
    },
    created() {},
    async mounted() {
      this.clipboard = await Tool.PasteFromClipboard(true);

      ['create', 'edit'].map(e => this.ResetImage(`${e}_user`));
      this.roles = BPA.api.Permissions('GET');
      this.countries = BPA.api.Countries('GET');
      this.phone.options = await this.GetPhoneCountryCodes();
      this.$eventHub.$on('CloseModal', (modal_name) => {
        if (this.modal[modal_name]) this.modal[modal_name].open = false;
        if (modal_name == 'view_user') {
          this.user = {};
          this.cached.user = {};
          BPA.cache.session({name: this.$options.name, set: {user: {}}});
          for (let i = 0; i < this.clickables.length; i++) {
            if (this.clickables[i].classList.contains('selected')) {
              this.clickables[i].classList.remove('selected');
            }
          }
        }
      });
      this.permissions = BPA.api.Permissions('GET');

      if (this.clipboard) {
        let query = this.clipboard || '';
        if (query) {
          this.search.query = query;
          this.cached = {};
        }
      } else {
        this.cached = BPA.cache.local({name: this.$options.name, get: 'search'});
        for (let key of Object.keys(this.cached)) this[key] = {...this[key], ...this.cached[key]};
        this.cached = {...this.cached, ...BPA.cache.session({name: this.$options.name, get: 'user'})};
      }

      this.QueryUsers();
    },
    methods: {
      withPopper(dropdownList, component, {width}) {
        dropdownList.style.width = width;
        let popper = createPopper(component.$refs.toggle, dropdownList, {
          placement: this.phone.placement,
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -1],
              }
            },
            {
              name: 'toggleClass',
              phase: 'write',
              enabled: true,
              fn({state}) {
                component.$el.classList.toggle(
                  'drop-up',
                  state.placement === 'top'
                )
              }
            }
          ]
        });
        return () => popper.destroy()
      },
      Hyperlink(props = {}) {
        return Tool.Hyperlink(props);
      },
      Capitalize(string) {
        return Tool.Capitalize(string);
      },
      TitleCase(string) {
        return Tool.TitleCase(string);
      },
      Alphabetize(list) {
        return list.sort((a, b) => {
          a = a.name; b = b.name;
          return a < b ? -1 : a > b ? 1 : 0;
        });
      },
      NumberFormat(number) {
        return Tool.NumberFormat(number);
      },
      DateFormat(date) {
        return Tool.DateFormat(date);
      },
      DateOnly(date) {
        date = date + ' 00:00:00';
        date = Tool.DateFormat(date).replace(Tool.TimeOnly(date), '');
        return date.replace(/,(?=[^,]*$)/, '');
      },
      CountryName(country_code) {
        return Tool.CountryName(country_code);
      },
      CloneObject(object = {}) {
        return Tool.CloneObject(object);
      },
      CurrencyFormat(number) {
        return Tool.CurrencyFormat(number);
      },
      preventKeybordInSelect() {
        /*
        setTimeout(() => {
          document.querySelectorAll('input[name=permissions-fake]').forEach(select => {
            ['input', 'change', 'keydown', 'keyup', 'keypress'].forEach(event => {
              select[`on${event}`] = (e) => {
              if  ([8, 27, 46].includes(e.keyCode)) {
                  console.log('prevet', e.key)
                }
                console.log(e)
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
              }
            });
          });
        });
        */
      },
      fuseSearch(options, search) {
        const fuse = new Fuse(options, {keys: ['label', 'name'], shouldSort: true});
        return search.length ? fuse.search(search).map(({ item }) => item) : fuse.list;
      },
      async GetPhoneCountryCodes() {
        return new Promise((resolve, reject) => {
          window.fetch(`../phone.json?${new Date().getTime()}`).then(response => {
            if (response && response.status == 200) return response.json();
          }).then((phone_codes = {}) => {
            const options = [];
            const country_codes = Object.keys(phone_codes);
            const countries = this.countries.map(c => c.iso_code_2);
            for (let country_code of countries) {
              if (country_codes.includes(country_code)) {
                options.push({
                  code: phone_codes[country_code], 
                  label: phone_codes[country_code],
                  name: this.CountryName(country_code),
                  iso: country_code
                });
              }
            }
            resolve(options);
          }).catch(reject);
        });
      },
      SearchUsers(event) {
        let input = event.target;
        let value = input.value.replace(/\s+/g, ' ').trim();
        this.search.query = value;
        input.value = value;
        this.QueryUsers();
        input.blur();
      },
      async QueryUsers() {
        if (this.search.query) {
          this.loading = true;
          await BPA.api.QueryUsers(this.search.query).then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            this.loading = false;
            if (!response.ok) return;
            let users = response.result || [];
            const items = [];
            users.forEach(user => {
              user.full_name = String(user.name + ' ' + user.surname).trim();
              user.status = (user.disabled ? 'in' : '') + 'active';
              items.push(user);
            });
            this.list.items = items;
            const sort = this.list.sort;
            this.Sort(this.list, 'items', sort.by, sort.prop, sort.column, sort.dir);
            BPA.cache.local({name: this.$options.name, set: {search: this.search}});
            this.$nextTick().then(() => {
              this.clickables = document.querySelectorAll('.clickable');
              if (this.cached.user && Object.keys(this.cached.user).length) {
                this.ViewUser(this.cached.user.id);
              } else if (this.list.items.length == 1 && this.clipboard) {
                this.ViewUser(this.list.items[0].id);
              }
            });
          }).catch(e => e);
        } else {
          await this.GetUsers();
        }
      },
      async GetUsers() {
        this.loading = true;
        await BPA.api.GetUsers().then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          this.loading = false;
          if (!response.ok) return;
          let users = response.result || [];
          const items = [];
          users.forEach(user => {
            user.full_name = String(user.name + ' ' + user.surname).trim();
            user.status = (user.disabled ? 'in' : '') + 'active';
            items.push(user);
          });
          this.list.items = items;          
          const sort = this.list.sort;
          this.Sort(this.list, 'items', sort.by, sort.prop, sort.column, sort.dir);
          BPA.cache.local({name: this.$options.name, set: {search: {query: ''}}});
          this.$nextTick().then(() => {
            this.clickables = document.querySelectorAll('.clickable');
            if (this.cached.user && Object.keys(this.cached.user).length) {
              this.ViewUser(this.cached.user.id);
            }
          });
        }).catch(e => e);
      },
      async GetUser(user_id) {
        this.loading = true;
        return await BPA.api.GetUser(user_id).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          this.loading = false;
          if (!response.ok) return;
          let user = response.result || [];
          let country = this.countries.find(option => option.id == user.res_country_id);
          if (country) user.country = {id: country.id, code: country.iso_code_2, name: country.name};
          user.permission_ids = user.permission_ids || [];
          BPA.cache.session({name: this.$options.name, set: {user}});
          this.user = user;
          return user;
        }).catch(e => e);
      },
      Sort(obj, list, by, prop = '', col = '', dir) {
        const sort = obj.sort;
        //const name = obj.name;
        const items = obj[list];
        const direction = (a, b) => {
          if (sort.dir == 'asc') {
            return a < b ? -1 : a > b ? 1 : 0;
          } else {
            return b < a ? -1 : b > a ? 1 : 0;
          }
        }
        if (sort.by == by) {
          sort.dir = sort.dir == 'desc' ? 'asc' : 'desc';
        }
        if (dir) sort.dir = dir;
        sort.by = by;
        sort.prop = prop;
        sort.column = col;
        items.sort((a, b) => {
          switch (by) {
            case 'date': 
              a = new Date(a[prop]);
              b = new Date(b[prop]);
              break;
            case 'number':
              a = parseInt(a[prop]);
              b = parseInt(b[prop]);
              break;
            case 'string':
              a = a[prop].toUpperCase();
              b = b[prop].toUpperCase();
              break;
            default:
              a = a[prop];
              b = b[prop];
          }
          return direction(a, b);
        });
        //console.log(obj, list, by, sort, prop)
      },
      async CreateUser() {
        this.ResetImage('create_user');
        for (let person in this.phone.selected) {
          this.phone.selected[person] = this.CloneObject(this.phone.default);
        }
        this.user.employee_no = await this.GetUserNextEmployeeNo();
        this.modal.create_user.open = true;
        this.preventKeybordInSelect();
        this.$nextTick().then(() => {
          document.querySelector('[tabindex="1"]').focus();
        });
      },
      async GetUserNextEmployeeNo() {
        this.loading = true;
        return await BPA.api.GetUserNextEmployeeNo().then(response => {
          return BPA.api.response({response, return: 'text'});
        }).then(response => {
          this.loading = false;
          if (!response.ok) return;
          return Number(response.result);
        }).catch(e => e);
      }, 
      async DownloadUsersCSV() {
        this.loading = true;
        return await BPA.api.GetUserExport().then(response => {
          return BPA.api.response({response, return: 'text'});
        }).then(response => {
          this.loading = false;
          if (!response.ok || !response.result) return;
          this.DownloadCSV(response.result);
        }).catch(e => e);
      }, 
      DownloadCSV(csv, file_name) {
        let a = document.createElement('a');
        a.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv);
        a.download = (file_name || 'PackShip_Users_Export') + '.csv';
        a.style.display = 'none';
        document.body.append(a);
        a.click(); 
        a.remove();
      }, 
      async ViewUser(user_id) {
        for (let i = 0; i < this.clickables.length; i++) {
          if (this.clickables[i].dataset.id == user_id) {
            this.clickables[i].classList.add('selected');
            if (this.cached.user && Object.keys(this.cached.user).length) {
              this.clickables[i].scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center'
              });
            }
            break;
          }
        }
        this.ResetImage('view_user');
        let image = await this.GetUserImage(user_id);
        let opacity = await this.OpacityRatio(image);
        if (opacity) this.modal.view_user.image.src = image;
        await this.GetUser(user_id).then(() => {
          this.modal.view_user.open = true;
        });
      },
      async EditUser(user_id) {
        this.ResetImage('edit_user');
        this.modal.edit_user.image.src = await this.GetUserImage(user_id);
        console.log(this.modal.edit_user.image)
        await this.GetUser(user_id).then(() => {
          for (let phone_prefix of ['prefix_phone', 'guardian_phone_predix']) {
            let person = /guardian/.test(phone_prefix) ? 'guardian' : 'user';
            if (this.user[phone_prefix]) {
              this.phone.selected[person] = this.phone.options.find(option => option.code == this.user[phone_prefix]);
            } else {
              this.phone.selected[person] = this.CloneObject(this.phone.default);
            }
          }
          this.modal.edit_user.open = true;
          this.preventKeybordInSelect();
        });
      },
      async DeleteUser(user_id) {
        await this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, delete user',
          disapprove: 'No, keep user',
          message: 'Deletes current user permanently from system.',
          type: 'danger'
        });
        this.$eventHub.$on('ValidateModalStop', approve => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          BPA.api.DeleteUser(user_id).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            if (!response.ok) return;
            this.QueryUsers();
            this.modal.edit_user.open = false;
            this.$eventHub.$emit('ShowMessages', {
              message: 'User successfully deleted',
              type: 'success',
              hide: 2000
            });
          }).catch(e => e);
        });
      },
      async ChangeImageData(image, event) {
        const image_data = this.modal[`${event}_user`].image;
        const blob = await window.fetch(image.data).then(res => res.blob()).then(blob => blob);
        const blob_url = window.URL.createObjectURL(blob);
        image_data.b64 = image.data;
        image_data.src = blob_url;
        /*
        console.log(event)
        console.log(image)
        console.log(image_data)
        console.log(blob_url)
        */
      },
      async GetImageFromURL(url) {
        return await new Promise((resolve, reject) => {
          if (!url) return reject();
          const image = new Image();
          image.setAttribute('crossOrigin', 'anonymous');
          image.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = image.width;
            canvas.height = image.height;
            let context = canvas.getContext('2d');
            context.drawImage(image, 0, 0);
            let base64 = canvas.toDataURL('image/png');
            resolve(base64);
          }
          image.src = url;
        });
      },
      ResetImage(modal_name) {
        this.modal[modal_name].image = {src: '',  b64: ''};
      },
      InitImage(modal_name, image) {
        this.modal[modal_name].image.src = image;
      },
      CropImage(modal_name, image) {
        this.modal[modal_name].image.b64 = image;
      },
      async OpacityRatio(base64) {
        const image = new Image();
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        return await new Promise(resolve => {
          if (!base64) return resolve(0);
          image.onload = () => {
            let opacity = 0;
            canvas.width = image.width;
            canvas.height = image.height;
            context.drawImage(image, 0, 0);
            const data = context.getImageData(0, 0, canvas.width, canvas.height).data;
            for (let i = 0; i < data.length; i += 4) opacity += data[i + 3];
            resolve((opacity / 255) / (data.length / 4));
          }
          image.src = base64;
        });
      },
      async GetUserImage(user_id) {
        return await new Promise((resolve, reject) => {
          BPA.api.GetUserImage(user_id).then(response => {
            return BPA.api.response({response, return: 'blob', 
              error: (message) => {
                if (response && response.status == 404) {
                  resolve(null); return response;
                }
                this.$eventHub.$emit('ShowMessages', {
                  message: message,
                  type: 'error',
                  close: true
                });
              }
            });
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            let reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(response.result);
          }).catch(reject);
        }).catch(e => e);
      },
      async UpdateUserImage(user) {
        return await new Promise((resolve, reject) => {
          BPA.api.UpdateUserImage(user).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            if (!response.ok) return reject();
            resolve(response);
          }).catch(reject);
        }).catch(e => e);
      },
      async DeleteUserImage(user_id) {
        return await new Promise((resolve, reject) => {
          BPA.api.DeleteUserImage(user_id).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            if (!response.ok) return reject();
            resolve(response);
          }).catch(reject);
        }).catch(e => e);
      },
      async CreateUserSubmit(e) {
        const form = this.ValidateForm(e.target);
        if (form.valid) {
          await BPA.api.CreateUser(form.data).then(response => {
            return BPA.api.response({response, return: 'json', 
              error: (message) => {
                if (response && response.status == 400) {
                  message = message.split(' - ')[1];
                }
                this.$eventHub.$emit('ShowMessages', {
                  message: message,
                  type: 'error',
                  close: response.status != 400
                });
              }
            });
          }).then(async response => {
            if (!response.ok || !response.result) return;
            this.$refs.image.Crop();
            let image = this.modal.create_user.image.b64;
            if (image) await this.UpdateUserImage({
              id: response.result.user_id, 
              image: image.split('base64,')[1]
            });
            this.QueryUsers();
            this.modal.create_user.open = false;
            this.$eventHub.$emit('ShowMessages', {
              message: 'User successfully created',
              type: 'success',
              hide: 2000
            });
          }).catch(e => e);
        } else {
          this.$eventHub.$emit('ShowMessages', {
            message: 'An error occured, fill the missing fields and try again.',
            type: 'error',
            hide: 2000
          });
        }
      },
      async EditUserSubmit(e) {
        let valid = true;
        const user = {};
        const form = e.target.closest('form');

        let NameInput = form.querySelector('input[name="name"]');
        if (NameInput.value != this.user.name) {
          user.name = NameInput.value;
        }

        let SurNameInput = form.querySelector('input[name="surname"]');
        if (SurNameInput.value != this.user.surname) {
          user.surname = SurNameInput.value;
        }

        let AddressInput = form.querySelector('input[name="address"]');
        if (AddressInput.value != this.user.address) {
          user.address = AddressInput.value;
        }

        let ZipcodeInput = form.querySelector('input[name="zipcode"]');
        if (ZipcodeInput.value != this.user.zipcode) {
          user.zipcode = ZipcodeInput.value;
        }

        let CityInput = form.querySelector('input[name="city"]');
        if (CityInput.value != this.user.city) {
          user.city = CityInput.value;
        }

        let PrefixInput = form.querySelector('input[name="prefix-phone"]');
        if (PrefixInput.value != this.user.prefix_phone) {
          user.prefix_phone = PrefixInput.value;
        }

        let ResCountryID = this.countries.find(country => country.iso_code_2 == this.phone.selected.user.iso).id;
        if (ResCountryID != this.user.res_country_id) {
          user.res_country_id = ResCountryID;
        }

        let PhoneInput = form.querySelector('input[name="phone"]');
        if (PhoneInput.value != '') {
          let phone_number = PhoneInput.value.replace(/\s/g, '');
          if (phone_number != this.user.phone) {
            if (!/^\+|00/.test(phone_number)) {
              user.phone = phone_number;
            } else {
              this.Error(PhoneInput, 'Country code not allowed');
              valid = false;
            }
          }
        } else {
          this.Error(PhoneInput, 'Phone must be set');
          valid = false;
        }

        let EmailInput = form.querySelector('input[name="email"]');
        if (EmailInput.value !== this.user.email) {
          user.email = String(EmailInput.value).toLowerCase();
        }

        let EmploymentDayInput = form.querySelector('input[name="employment-day"]');
        if (EmploymentDayInput.value != '') {
          let date = new Date(parseInt(EmploymentDayInput.value));
          date = Tool.DateToISO(date).split(' ')[0];
          if (date != this.user.employment_day) {
            user.employment_day = date;
          }
        } else {
          this.Error(EmploymentDayInput, 'Start date must be set');
          valid = false;
        }

        let PermissionsInput = form.querySelector('input[name="permissions"]');
        let roles = PermissionsInput.value.split(', ').map(x => parseInt(x));
        if (!this.ArraysEqual(roles, this.user.permission_ids)) {
          if (roles.filter(x => !isNaN(x)).length) {
            user.permission_ids = roles;
          } else {
            this.Error(PermissionsInput, 'Permissions must be set');
            valid = false;
          }
        }

        let StatusInput = form.querySelector('input[name="status"]');
        if (StatusInput.value != '') {
          let disabled = StatusInput.value != 1;
          if (disabled != this.user.disabled) {
            user.disabled = disabled;
          }
        } else {
          this.Error(StatusInput, 'Status must be set');
          valid = false;
        }

        let PasswordInput = form.querySelector('input[name="password"]');
        let RepeatPasswordInput = form.querySelector('input[name="repeat-password"]');
        if (PasswordInput.value != '' || RepeatPasswordInput.value != '') {
          if (PasswordInput.value == RepeatPasswordInput.value &&
            PasswordInput.value != '' && RepeatPasswordInput.value != '') {
            user.password = PasswordInput.value;
          } else {
            this.Error(RepeatPasswordInput, 'Password and repeat password must match');
            valid = false;
          }
        }

        let CommissionPayInput = form.querySelector('input[name="commission-pay"]');
        if (CommissionPayInput.value != '') {
          if (parseFloat(CommissionPayInput.value) != this.user.commission_pay) {
            user.commission_pay = parseFloat(CommissionPayInput.value);
          }
        }

        let CommissionPayPickInput = form.querySelector('input[name="commission-pay-pick"]');
        if (CommissionPayPickInput.value != '') {
          if (parseFloat(CommissionPayPickInput.value) != this.user.commission_pay_pick) {
            user.commission_pay_pick = parseFloat(CommissionPayPickInput.value);
          }
        }
      
        let HourlyPayInput = form.querySelector('input[name="hourly-pay"]');
        if (HourlyPayInput.value != '') {
          if (parseFloat(HourlyPayInput.value) != this.user.hourly_pay) {
            user.hourly_pay = parseFloat(HourlyPayInput.value);
          }
        }

        let HourlyGuaranteedSalaryInput = form.querySelector('input[name="hourly-guaranteed-salary"]');
        if (HourlyGuaranteedSalaryInput.value != '') {
          if (parseFloat(HourlyGuaranteedSalaryInput.value) != this.user.hourly_guarantee_salary) {
            user.hourly_guarantee_salary = parseFloat(HourlyGuaranteedSalaryInput.value);
          }
        }

        let EmployeeNoInput = form.querySelector('input[name="employee-no"]');
        if (EmployeeNoInput.value != '') {
          user.employee_no = EmployeeNoInput.value || '';
          if (EmployeeNoInput.value != this.user.employee_no) {
            user.employee_no = EmployeeNoInput.value || '';
          }
        } else {
          this.Error(EmployeeNoInput, 'Employee number must be set');
          valid = false;
        }

        let SocialSecurityInput = form.querySelector('input[name="social-security-no"]');
        if (SocialSecurityInput.value != '') {
          if (SocialSecurityInput.value != this.user.social_security_no) {
            user.social_security_no = SocialSecurityInput.value || '';
          }
        } else {
          this.Error(SocialSecurityInput, 'Social security number must be set');
          valid = false;
        }

        let RegNoInput = form.querySelector('input[name="reg-no"]');
        if (RegNoInput.value != this.user.reg_no) {
          user.reg_no = RegNoInput.value || '';
        }

        let AccountNoInput = form.querySelector('input[name="account-no"]');
        if (AccountNoInput.value != this.user.account_no) {
          user.account_no = AccountNoInput.value || '';
        }

        let GuardianNameInput = form.querySelector('input[name="guardian-name"]');
        if (GuardianNameInput.value != this.user.guardian_name) {
          user.guardian_name = GuardianNameInput.value || '';
        }

        let GuardianSurNameInput = form.querySelector('input[name="guardian-surname"]');
        if (GuardianSurNameInput.value != this.user.guardian_surname) {
          user.guardian_surname = GuardianSurNameInput.value || '';
        }

        let GuardianEmailInput = form.querySelector('input[name="guardian-email"]');
        let guardian_email = String(GuardianEmailInput.value).toLowerCase();
        if (guardian_email != this.user.guardian_email) {
          user.guardian_email = guardian_email || '';
        }

        let GuardianPrefixInput = form.querySelector('input[name="guardian-prefix-phone"]');
        user.guardian_phone_predix = GuardianPrefixInput.value;

        let GuardianPhoneInput = form.querySelector('input[name="guardian-phone"]');
        let guardian_phone = GuardianPhoneInput.value.replace(/\s/g, '');
        if (guardian_phone != this.user.guardian_phone) {
          if (!/^\+|^00/.test(guardian_phone)) {
            user.guardian_phone = guardian_phone;
          } else {
            user.guardian_phone = '';
          }
        }

        if (!valid) {
          this.$eventHub.$emit('ShowMessages', {
            message: 'User not updated, error occured. Please try again',
            type: 'error',
            hide: 2000
          });
          return;
        }

        let image = null;
        this.$refs.image.Crop();
        clearTimeout(this.timeout);
        await new Promise(resolve => {
          this.timeout = setTimeout(() => {
            resolve(image = this.modal.edit_user.image);
          }, 100);
        });
        let update_image = image && image.src != image.b64;
        if (!update_image && !Object.keys(user).length) {
          return this.modal.edit_user.open = false;
          /*
          this.$eventHub.$emit('ShowMessages', {
            message: 'User not updated',
            type: 'warning',
            hide: 2000
          });
          */
        }
        this.loading = true;
        BPA.api.UpdateUser({
          user_id: this.user.id,
          user_data: user
        }).then(response => {
          return BPA.api.response({response, 
            error: (message) => {
              if (response && response.status == 400) {
                message = message.split(' - ')[1];
              }
              this.$eventHub.$emit('ShowMessages', {
                message: message,
                type: 'error',
                close: response.status != 400
              });
            }
          });
        }).then(async response => {
          this.loading = false;
          if (!response.ok) return;
          if (update_image && image.b64) {
            await this.UpdateUserImage({
              id: this.user.id, 
              image: image.b64.split('base64,')[1]
            });
          }
          this.QueryUsers();
          this.modal.edit_user.open = false;
          this.$eventHub.$emit('ShowMessages', {
            message: 'User successfully updated',
            type: 'success',
            hide: 2000
          });
        }).catch(e => e);
      },
      GetRoles(ids) {
        if (ids !== undefined) {
          let roles = [];
          ids.map(id => {
            let el;
            if (this.permissions[id]) {
              if (typeof this.permissions[id] == 'object') {
                el = this.permissions[id].name;
              } else {
                el = this.permissions[id];
              }
            } else {
              for (let item in this.permissions) {
                let element = this.permissions[item];
                if (typeof element == 'object') {
                  if (element.option[id]) {
                    el = element.name + ' (Admin)';
                  }
                }
              }
            }
            roles.push(el);
          });
          return roles.sort().join(', ');
        } else {
          return '';
        }
      },
      ValidateForm(form) {
        let valid = true;
        const user = {};

        let NameInput = form.querySelector('input[name="name"]');
        if (NameInput.value != '') {
          user.name = NameInput.value;
        } else {
          this.Error(NameInput, 'First name must be set');
          valid = false;
        }

        let SurNameInput = form.querySelector('input[name="surname"]');
        if (SurNameInput.value != '') {
          user.surname = SurNameInput.value;
        } else {
          this.Error(SurNameInput, 'Last name must be set');
          valid = false;
        }

        let AddressInput = form.querySelector('input[name="address"]');
        if (AddressInput.value != '') {
          user.address = AddressInput.value;
        } else {
          this.Error(AddressInput, 'Address must be set');
          valid = false;
        }

        let ZipcodeInput = form.querySelector('input[name="zipcode"]');
        if (ZipcodeInput.value != '') {
          user.zipcode = ZipcodeInput.value;
        } else {
          this.Error(ZipcodeInput, 'Zipcode must be set');
          valid = false;
        }

        let CityInput = form.querySelector('input[name="city"]');
        if (CityInput.value != '') {
          user.city = CityInput.value;
        } else {
          this.Error(CityInput, 'City must be set');
          valid = false;
        }

        let EmailInput = form.querySelector('input[name="email"]');
        if (EmailInput.value != '') {
          user.email = String(EmailInput.value).toLowerCase();
        } else {
          this.Error(EmailInput, 'Email must be set');
          valid = false;
        }

        let PrefixInput = form.querySelector('input[name="prefix-phone"]');
        user.prefix_phone = PrefixInput.value;

        let ResCountryID = this.countries.find(country => country.iso_code_2 == this.phone.selected.user.iso).id;
        user.res_country_id = ResCountryID;

        let PhoneInput = form.querySelector('input[name="phone"]');
        if (PhoneInput.value != '') {
          let phone_number = PhoneInput.value.replace(/\s/g, '');
          if (!/^\+|^00/.test(phone_number)) {
            user.phone = phone_number;
          } else {
            this.Error(PhoneInput, 'Country code not allowed');
            valid = false;
          }
        } else {
          this.Error(PhoneInput, 'Phone must be set');
          valid = false;
        }

        let EmploymentDayInput = form.querySelector('input[name="employment-day"]');
        if (EmploymentDayInput.value != '') {
          let date = new Date(parseInt(EmploymentDayInput.value));
          user.employment_day = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        } else {
          this.Error(EmploymentDayInput, 'Start date must be set');
          valid = false;
        }

        let PermissionsInput = form.querySelector('input[name="permissions"]');
        if (PermissionsInput.value != '') {
          user.permission_ids = PermissionsInput.value.split(', ').map(x => parseInt(x)).filter(x => !isNaN(x));
        } else {
          this.Error(PermissionsInput, 'Permissions must be set');
          valid = false;
        }

        let StatusInput = form.querySelector('input[name="status"]');
        if (StatusInput.value != '') {
          user.disabled = StatusInput.value != 1;
        } else {
          this.Error(StatusInput, 'Status must be set');
          valid = false;
        }

        let PasswordInput = form.querySelector('input[name="password"]');
        let RepeatPasswordInput = form.querySelector('input[name="repeat-password"]');
        if (PasswordInput.value == '') {
          this.Error(PasswordInput, 'Password must be set');
          valid = false;
        }
        if (RepeatPasswordInput == '') {
          this.Error(RepeatPasswordInput, 'Repeat password must be set');
          valid = false;
        }

        if (PasswordInput.value == RepeatPasswordInput.value &&
          PasswordInput.value != '' && RepeatPasswordInput.value != '') {
          user.password = PasswordInput.value
        } else {
          this.Error(RepeatPasswordInput, 'Password and repeat password must match');
          valid = false;
        }

        let CommissionPayInput = form.querySelector('input[name="commission-pay"]');
        if (CommissionPayInput.value != '') {
          user.commission_pay = parseFloat(CommissionPayInput.value);
        } else {
          user.commission_pay = parseFloat(0);
        }

        let CommissionPayPickInput = form.querySelector('input[name="commission-pay-pick"]');
        if (CommissionPayPickInput.value != '') {
          user.commission_pay_pick = parseFloat(CommissionPayPickInput.value);
        } else {
          user.commission_pay_pick = parseFloat(0);
        }

        let HourlyPayInput = form.querySelector('input[name="hourly-pay"]');
        if (HourlyPayInput.value != '') {
          user.hourly_pay = parseFloat(HourlyPayInput.value);
        } else {
          user.hourly_pay = parseFloat(0);
        }

        let HourlyGuaranteedSalaryInput = form.querySelector('input[name="hourly-guaranteed-salary"]');
        if (HourlyGuaranteedSalaryInput.value != '') {
          user.hourly_guarantee_salary = parseFloat(HourlyGuaranteedSalaryInput.value);
        } else {
          user.hourly_guarantee_salary = parseFloat(0);
        }

        let EmployeeNoInput = form.querySelector('input[name="employee-no"]');
        if (EmployeeNoInput.value != '') {
          user.employee_no = EmployeeNoInput.value || '';
        } else {
          this.Error(EmployeeNoInput, 'Employee number must be set');
          valid = false;
        }

        let SocialSecurityInput = form.querySelector('input[name="social-security-no"]');
        if (SocialSecurityInput.value != '') {
          user.social_security_no = SocialSecurityInput.value || '';
        } else {
          this.Error(SocialSecurityInput, 'Social security number must be set');
          valid = false;
        }

        let RegNoInput = form.querySelector('input[name="reg-no"]');
        user.reg_no = RegNoInput.value || '';

        let AccountNoInput = form.querySelector('input[name="account-no"]');
        user.account_no = AccountNoInput.value || '';

        let GuardianNameInput = form.querySelector('input[name="guardian-name"]');
        user.guardian_name = GuardianNameInput.value || '';
        
        let GuardianSurNameInput = form.querySelector('input[name="guardian-surname"]');
        user.guardian_surname = GuardianSurNameInput.value || '';
        
        let GuardianEmailInput = form.querySelector('input[name="guardian-email"]');
        user.guardian_email = String(GuardianEmailInput.value).toLowerCase();
        
        let GuardianPrefixInput = form.querySelector('input[name="guardian-prefix-phone"]');
        user.guardian_phone_predix = GuardianPrefixInput.value;

        let GuardianPhoneInput = form.querySelector('input[name="guardian-phone"]');
        let guardian_phone = GuardianPhoneInput.value.replace(/\s/g, '');
        if (!/^\+|^00/.test(guardian_phone)) {
          user.guardian_phone = guardian_phone;
        } else {
          user.guardian_phone = '';
        }
        
        return {
          valid: valid,
          data: user
        };
      },
      ArraysEqual(a, b) {
        if (a === b) return true;
        if (a == null || b == null) return false;
        if (a.length != b.length) return false;
        
        a.sort();
        b.sort();

        for (var i = 0; i < a.length; ++i) {
          if (a[i] !== b[i]) return false;
        }
        return true;
      },
      ClickOpen(e) {
        if (!e) return;
        let row = {};
        let elm = e.target;
        if (elm.localName != 'tr') row = elm.closest('tr');
        const filter = ['actions', 'icon dots'];
        const mousedown = (state) => {
          if (state === true || state === false) {
            row.mousedown = state;
          } else {
            return row.mousedown;
          }
        }
        for (let i = 0; i < this.clickables.length; i++) {
          if (this.clickables[i] != row) {
            this.clickables[i].classList.remove('hover', 'selected');
          }
        }
        if (elm.localName == 'a') return;
        if (filter.some(x => new RegExp(x).test(elm.className))) return;
        if (e.type == 'mousedown') {
          mousedown(e.which == 1);
        }
        if (e.type == 'mousemove') {
          mousedown(false);
        }
        if (e.type == 'mouseup') {
          if (mousedown()) {
            this.ViewUser(row.dataset.id);
          }
          mousedown(false);
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../../assets/style/variables/icons';

  .user-image {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    background-size: 326px;
    background-position: center;
    background-repeat: no-repeat;

    &.background {
      background-image: $user-image;
    }

    &::before,
    &::after {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      display: block;
      background-repeat: inherit;
      background-position: inherit;
    }

    &::before {
      opacity: 0.5;
      position: absolute;
      background-size: cover;
      background-image: var(--src);
      transform-origin: center;
      transform: scale(1.1);
      filter: var(--filter);
    }

    &::after {
      z-index: 1;
      margin: 0 auto;
      position: relative;
      background-size: 326px;
      backdrop-filter: var(--backdrop);
      //clip-path: circle(163px at center);
      background-image: var(--src), linear-gradient(white, white);
    }
  }

  .form-action {
    & > * + * {
      margin-left: 20px;
    }
  }
</style>
